.navbar-light {
    background-color: #525252;
    z-index: 2;
}

.topNavbar {
    background-color: #f1f1f1;
}

.wlcmText {
    font-size: 1.4rem;
    font-weight: 400;
    margin-bottom: 0;
    color: #666666;
}

.paddingTopnav {
    padding: 1rem 12rem;
}

.brandName {
    color: var(--appThemeColor);
    font-weight: 500;
}

.navbarText {
    font-size: 1.3rem;
    font-weight: 400;
    margin-bottom: 0;
    color: white;
}

.navbarTextNum {
    font-size: 1.5rem;
    font-weight: 500;
}

.navbarInfoDiv {
    display: flex;
    align-items: center;
    border-left: 2px solid white;
    padding-left: 5rem;
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show>.nav-link,
.navbar-light .navbar-nav .nav-link,
.navbar-light .navbar-nav .nav-link:hover {
    color: white;
    font-size: 1.4rem;
    font-weight: 400;
}

.navbar-light .navbar-nav .nav-link.active {
    border-bottom: .3rem solid var(--appThemeColor);
    padding-left: 0;
    padding-right: 0;
    display: inline-block;
}

.navbar-light .navbar-nav .nav-link:focus {
    color: white;
}

.navbar-toggler {
    filter: invert(1);
    border: none;
}

.navbar-toggler-icon {
    width: 3.8rem;
    height: 2.4rem;
}

.profileDropdown a {
    font-size: 1.4rem;
    font-weight: 500;
    color: white;
    width: max-content;
}

.profileDropdown a:hover {
    color: white;
}


.dropdown-menu {
    width: 42.2rem;
    left: -3.8rem !important;
    padding: 0.4rem;
    margin-top: 0rem;
    opacity: 0;
    visibility: hidden;
    transition: all .6s cubic-bezier(0.165, 0.84, 0.44, 1);
    display: block !important;
    right: 0;
    left: unset !important;
}

.userDropdownMenu {
    width: 5rem;
}

.dropdown-menu.show {
    margin-top: .6rem;
    opacity: 1;
    visibility: visible;
}

.profileDropdown {
    text-align: -webkit-center;
}

.profileDropdown .dropdown-item {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 0;
}

.profileDropdown .dropdown-item:focus {
    background-color: white;
}

.profileDropdown .dropdown-item:hover {
    background-color: var(--appThemeColor);
    color: white;
}

.brand-logo {
    height: 7rem;
}

.topnavText .nav-link {
    font-size: 1.4rem;
    font-weight: 400;
    color: #666666;
    display: flex;
    align-items: center;
}

.midnavText .nav-link {
    font-size: 1.4rem;
    font-weight: 500;
    color: #666666;
    display: flex;
    align-items: center;
}

.mainSearchbar {
    height: 4.8rem;
    background-color: #F3F9FB;
    border: 0;
    border-radius: 1rem;
    width: 100%;
    text-indent: 4rem;
    font-size: 1.4rem;
}

.mainSearchbar:focus {
    outline: none;
}

.searchIcon {
    position: absolute;
    left: 1.2rem;
    top: 1.5rem;
}

.cartItemsCount {
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    background-color: var(--appThemeColor);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    position: absolute;
    top: -1.3rem;
    right: -1rem;
}

.cartDropHeading {
    font-size: 2.4rem;
    font-weight: 600;
    border-bottom: 2px solid #f1f1f1;
    text-align: start;
}

.cardProdDiv {
    background-color: #f1f1f1;
    border-radius: 1rem;
    display: flex;
    align-items: center;
}

.cartDropImgDiv {
    height: 10rem;
    width: 10rem;
    border-radius: 1rem;
    background-color: white;
    padding: 1rem;
    margin: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cartDropDesc {
    font-size: 1.4rem;
    font-weight: 600;
    text-align: initial;
    margin-right: 1rem;
}

.cartDropPrice {
    font-size: 1.4rem;
    font-weight: 600;
    color: var(--appThemeColor);
    margin-bottom: .2rem;
    text-align: start;
}

.cartDropAdv {
    font-size: 1.1rem;
    font-weight: 400;
    color: var(--appThemeColor);
    margin-bottom: 0;
    text-align: start;
}

.cartDropCounter {
    height: 2.6rem;
    width: 7rem;
    border-radius: 3rem;
    background-color: #97e1a061;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 2;
}

.subTotal {
    font-size: 1.6rem;
    font-weight: 400;
}

.cartSubtotal {
    font-size: 1.6rem;
    font-weight: 600;
}

.cartFooterMargin {
    margin-right: 10rem;
    padding: 2rem;
}

.cartDropFooter {
    border-top: 2px solid #f1f1f1;
}

.cartDropdown {
    position: absolute;
    right: 0;
    z-index: 3;
    background-color: white;
    width: 42.2rem;
    height: 50rem;
    overflow: auto;
    border-radius: .5rem;
    border: 1px solid #f1f1f1;
}

.cartDropdown li {
    list-style: none;
}

.marquee {
    position: relative;
    width: 50%;
    white-space: nowrap;
    overflow: hidden;
    padding: 10px 0;
    z-index: 1;
}

.marquee-content {
    display: flex;
    white-space: nowrap;
    animation: marquee 10s linear infinite;
    gap: 5rem;
}

.resultsList {
    font-size: 1.4rem;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 3;
    background: white;
    width: 100%;
    padding-left: 0;
}

.resultsList li {
    border-bottom: 1px solid silver;
    padding: 1rem;
    cursor: pointer;
}

.resultsList li:hover {
    background-color: #f0f0f0;
}

@keyframes marquee {
    0% {
        transform: translateX(-50%);
    }

    100% {
        transform: translateX(0);
    }
}

@media (max-width:991px) {
    .dropdown-menu {
        left: -2rem !important;
    }
}

@media (max-width:768px) {
    .dropdown-menu.show {
        position: inherit;
        left: 0rem !important;
    }
}