.whiteBtn {
    border: 2px solid var(--appThemeColor);
    height: 5.6rem;
    color: var(--appThemeColor);
    font-size: 1.4rem;
    font-weight: 600;
    background-color: white;
    border-radius: .8rem;
    padding: 0 2rem;
    width: max-content;
}

.greenBtn {
    border: none;
    height: 5.6rem;
    color: white;
    font-size: 1.4rem;
    font-weight: 600;
    background-color: var(--appThemeColor);
    border-radius: .8rem;
    padding: 0 2rem;
    width: max-content;
    line-height: 1;
}

.blackBtn {
    border: 1px solid black;
    height: 3rem;
    color: black;
    font-size: 1.3rem;
    font-weight: 400;
    background-color: white;
    border-radius: 1rem;
    padding: 0 2rem;
    width: max-content;
}