.modal-content {
    border-radius: 1rem;
}

.modal-header {
    border: 0;
}

.modalLabel {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 0;
    color: var(--appThemeColor);
}

hr {
    color: var(--appThemeColor);
    height: 2px;
}

.lgModals {
    max-height: 85vh;
    overflow: auto;
}